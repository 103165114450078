@mixin m {
  @media only screen and (max-width: "740px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "370px") {
    @content; } }
@mixin d {
  @media only screen and (min-width: "741px") {
    @content; } }

@mixin w {
  @media only screen and (max-width: "1240px") {
    @content; } }

// +font(, )
// =fl
//   font-family: '', serif

@mixin f {
  font-family: 'proxima-nova', Arial, sans-serif; }

$black: #000;
$white: #fff;

$red: #bd0217;
$yellow: #ffa200;
$green: #006f00;

$border: #dadada;
$border-dark: #cfcfcf;
$bg: #f8f8f8;

$c82: #828282;

$link: #287c9f;

$gray: #9e9e9e;
$gray-dark: #878686;



