.field {
  &__label {
    margin-bottom: 8px;
    font-size: 14px;
    letter-spacing: .5px;
    text-transform: uppercase;
    @include m {
      margin-bottom: 4px;
      font-size: 14px; } }
  &__input {
    width: 100%;
    height: 33px;
    padding: 0 14px;
    border: 1px solid $border;
    border-radius: 4px;
    transition: border-color .2s;
    &_radius_l {
      border-radius: 4px 0 0 4px; }
    &_radius_no {
      border-radius: 0; }
    &_radius_r {
      border-radius: 0 4px 4px 0; }
    &:focus {
      border-color: $red; }
    &[disabled] {
      background: #EBEBEB; }
    @include m {
      height: 40px;
      border-radius: 0; } }
  &__error_msg {
    color: red; }
  &_order &__label {
    font-size: 12px;
    letter-spacing: -.01px; } }

