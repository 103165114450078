.section {
  margin-bottom: 70px;
  @include m {
    margin-bottom: 25px; }
  &__head {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    align-items: baseline;
    @include m {
      margin-bottom: 5px; } }
  &__rate {
    margin-left: 60px;
    font-size: 25px; }
  &__foot {
    height: 17px;
    margin-top: 27px;
    border-bottom: 1px solid $border;
    text-align: center; }
  &__btn {
    min-width: 213px;
    box-shadow: -5px 0 0 0 white, 5px 0 0 0 white;
    font-size: 15px;
    font-weight: 700;
    .icon {
      margin-left: 10px;
      font-size: 10px;
      vertical-align: middle; } }
  &__rating {
    position: relative;
    top: 2px;
    margin-left: auto; } }


