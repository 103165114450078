.params {
  font-size: 19px;
  @include m {
    font-size: 17px; }
  &__title {
    margin-bottom: 3px;
    font-size: 25px;
    font-weight: 700; }
  &__list {
    margin-bottom: 5px; }
  &__item {
    position: relative;
    margin-bottom: 6px;
    padding-left: 15px;
    @include m {
      margin-bottom: 4px; }
    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 4px;
      height: 4px;
      background: black;
      border-radius: 50%; } } }

