.header {
  position: relative;
  padding: 15px 0 16px;
  background: $black;
  z-index: 10;
  @include m {
    padding: 12px 0; }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    bottom: 0;
    background-image: linear-gradient(91deg, #f57b05 0%, #f13c04 86%, #bd0217 99%, #bd0217 100%); }
  &.upper {
    z-index: 1020; }
  &__center {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    @include r(1420) {
      padding: 0 20px; }
    @include m {
      position: relative;
      width: 100%; } }
  &__burger {
    display: none;
    height: 25px;
    border-radius: 3px;
    background: rgba(white,.2);
    flex: 0 0 65px;
    border: none;
    font-size: 18px;
    color: white;
    @include m {
      display: block; } }
  &__logo {
    width: 242px;
    height: 26px;
    margin-right: 52px;
    background-size: 242px 26px;
    @include m {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      transform: translate(-55%,-50%) scale(.5); }
    @include s {
      transform: translate(-53%,-50%) scale(.4); } }
  &__search {
    position: relative;
    flex: 1 0 auto;
    @include m {
      max-width: 30px;
      height: 25px; }
    &-input {
      width: 100%;
      height: 43px;
      padding: 0 16px;
      border-radius: 3px;
      background: $white;
      font-size: 16px;
      @include m {
        position: absolute;
        top: 0;
        right: 0;
        width: 30vw;
        height: 25px;
        padding: 0 8px;
        font-size: 11px;
        transition: all .4s;
        &:focus {
          top: -3px;
          width: calc(100vw - 20px);
          height: 31px; }
        &::placeholder {
          opacity: 0; } }
      @include sm {
        width: 24vw; } }
    &-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 64px;
      height: 43px;
      padding: 0;
      border-radius: 0 3px 3px 0;
      font-size: 0;
      @include m {
        width: 30px;
        height: 25px;
        background: transparent; }
      .icon {
        font-size: 22px;
        fill: $white;
        @include m {
          font-size: 16px;
          fill: #303131; } } } }
  &__phone {
    display: block;
    position: relative;
    margin-left: 25px;
    padding-left: 22px;
    font-size: 18px;
    color: $white;
    overflow: hidden;
    @include m {
      display: none; }
    .icon {
      position: absolute;
      top: 3px;
      left: 0;
      font-size: 13px;
      fill: $c82; } }
  &__logout {
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 9px;
    color: white;
    font-size: 16px;
    text-decoration: underline;
    letter-spacing: 1px;
    @include m {
      display: none; } }
  &__enter {
    float: right;
    margin-left: 20px;
    display: flex;
    @include m {
      display: none; }
    &-btn {
      min-width: 94px;
      padding: 9px 15px 10px;
      background: transparent;
      border: 1px solid rgba($white,.5);
      font-size: 16px; } }
  &__cart {
    position: relative;
    margin-left: 25px;
    padding-bottom: 2px;
    @include m {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 50px;
      height: 50px;
      margin: 0;
      padding: 16px 0 0 5px;
      background: #e2e2e2;
      z-index: 49; }
    .icon {
      display: block;
      font-size: 18px;
      fill: white;
      @include m {
        font-size: 26px;
        fill: #595959; } } }
  &__counter {
    position: absolute;
    top: -7px;
    right: -12px;
    min-width: 16px;
    height: 16px;
    padding: 0 3px;
    border-radius: 10px;
    background: $red;
    font-size: 13px;
    font-weight: 700;
    color: white;
    line-height: 16px;
    text-align: center;
    @include m {
      top: 6px;
      right: 6px;
      min-width: 20px;
      height: 20px;
      padding: 0 5px;
      font-size: 18px;
      line-height: 22px; } } }
