.cssload-container {
  position: relative;
  display: none;
  height: 136px;
  &-loading {
    display: block;
    height: 250px; } }

.cssload-whirlpool {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgb(204, 204, 204);
  border-left-color: rgb(0, 0, 0);
  border-radius: 974px;
  -o-border-radius: 974px;
  -ms-border-radius: 974px;
  -webkit-border-radius: 974px;
  -moz-border-radius: 974px;
  &::before, &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgb(204, 204, 204);
    border-left-color: rgb(0, 0, 0);
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px; }
  margin: -24px 0 0 -24px;
  height: 49px;
  width: 49px;
  animation: cssload-rotate 2.2s linear infinite;
  -o-animation: cssload-rotate 2.2s linear infinite;
  -ms-animation: cssload-rotate 2.2s linear infinite;
  -webkit-animation: cssload-rotate 2.2s linear infinite;
  -moz-animation: cssload-rotate 2.2s linear infinite;
  &::before {
    content: "";
    margin: -22px 0 0 -22px;
    height: 43px;
    width: 43px;
    animation: cssload-rotate 2.2s linear infinite;
    -o-animation: cssload-rotate 2.2s linear infinite;
    -ms-animation: cssload-rotate 2.2s linear infinite;
    -webkit-animation: cssload-rotate 2.2s linear infinite;
    -moz-animation: cssload-rotate 2.2s linear infinite; }
  &::after {
    content: "";
    margin: -28px 0 0 -28px;
    height: 55px;
    width: 55px;
    animation: cssload-rotate 4.4s linear infinite;
    -o-animation: cssload-rotate 4.4s linear infinite;
    -ms-animation: cssload-rotate 4.4s linear infinite;
    -webkit-animation: cssload-rotate 4.4s linear infinite;
    -moz-animation: cssload-rotate 4.4s linear infinite; } }

@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg); } }


@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg); } }


@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg); } }


@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg); } }


@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg); } }
