.amount {
  display: flex;
  width: 90px;
  &__btn,
  &__input {
    width: 30px;
    height: 30px;
    font-size: 18px; }
  &__input {
    border: 1px solid $border;
    border-radius: 2px;
    text-align: center;
    @include m {
      border-radius: 0; } }
  &__btn {
    border-radius: 0;
    @include m {
      border: solid $border;
      &:first-child {
        border-width: 1px 0 1px 1px; }
      &:last-child {
        border-width: 1px 1px 1px 0; } }
    &[disabled] {
      color: rgba(black,.3);
      cursor: default; } } }
