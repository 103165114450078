﻿.chapter {
  padding: 30px 0;
  background: white;
  line-height: 1.3;
  @include m {
    padding: 15px 0;
    box-shadow: 0 2px 0px 0.4px rgba(0, 0, 0, 0.05); }
  & + & {
    @include m {
      margin-top: 5px; } }
  &__overview {
    @include m {
      overflow: hidden; } }
  &__promo {
    margin: -30px 0 30px;
    @include m {
      margin: -15px 0 15px; } }
  &__head {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    @include m {
      margin-bottom: 10px; } }
  &__title {
    font-size: 35px;
    @include m {
      margin-right: 10px;
      font-size: 23px;
      line-height: 1; } }
  &__bold {
    font-weight: 700; }
  &__line {
    height: 5px;
    margin: 0 20px;
    background: rgba($border,.4);
    flex: 1 0 auto;
    @include m {
      display: none; } }
  &__link {
    display: flex;
    font-size: 20px;
    font-style: italic;
    color: $red;
    align-items: center;
    @include m {
      margin: 5px 0 0 auto;
      font-size: 13px; }
    .icon {
      margin-left: 8px;
      font-size: 12px;
      fill: $red;
      @include m {
        font-size: 8px; } } } }
