.footer {
  padding: 25px 0 30px;
  background: $black;
  @include m {
    display: none; }
  &__row {
    display: flex;
    justify-content: space-between; }
  &__col {
    padding-right: 35px;
    box-sizing: border-box;
    &:nth-child(1) {
      flex: 0 0 238px; }
    &:nth-child(2),
    &:nth-child(3) {
      flex: 0 0 190px; }
    &:nth-child(5) {
      padding-right: 0 !important; } }
  &__logo {
    display: block;
    width: 138px;
    height: 15px;
    margin-bottom: 10px;
    background-size: 138px 15px; }
  &__copyright {
    font-size: 12px;
    color: #828282; }
  &__nav {
    padding-left: 10px; }
  &__link {
    display: block;
    position: relative;
    border-bottom: 1px solid #1c1c1c;
    font-size: 16px;
    color: $white;
    &:not(:last-child) {
      margin-bottom: 4px; }
    .icon {
      position: absolute;
      top: 4px;
      left: -10px;
      font-size: 5px;
      fill: rgba($white,.5); } } }
