.order {
  padding: 15px 20px;
  border: 1px solid $border;
  border-radius: 4px;
  background: white;
  @include m {
    margin: 0 -10px;
    padding: 15px 10px 0;
    border-radius: 0;
    border-width: 1px 0 0; }
  &__head {
    position: relative;
    margin-bottom: 10px; }
  &__body {
    @include m {
      margin: 0 -10px;
      padding: 0 10px;
      border-bottom: 1px solid $border;
      box-shadow: 0 8px 0 0 #f8f9fd; } }
  &__toggle {
    display: none;
    margin-bottom: 35px;
    @include m {
      display: block; } }
  &__title {
    margin-right: 20px;
    font-size: 36px;
    font-weight: 700;
    @include m {
      font-size: 23px; } }
  &__type {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 115px;
    line-height: 1.2; }
  &__entity {
    border-bottom: 1px solid rgba(#7F7F7F,.3);
    color: #7F7F7F;
    @include m {
      font-size: 14px; } }
  &__section {
    margin-bottom: 25px;
    @include m {
      margin-bottom: 30px; } }
  &__panel {
    margin-bottom: 15px; }
  &__subtitle {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    @include m {
      margin-bottom: 15px; } }
  &__row {
    display: flex;
    margin: 0 -5px 6px;
    @include m {
      display: block; } }
  &__col {
    padding: 0 5px;
    flex: 0 1 67%;
    @include m {
      margin-bottom: 17px; }
    &_sm {
      flex: 0 0 28%; }
    &_md {
      flex: 0 0 33%; }
    &_sm-1 {
      flex: 0 0 10%; } }
  &__flex {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    align-items: baseline;
    flex-wrap: wrap;
    @include m {
      display: block;
      margin-bottom: 15px; }
    &_payment {
      @include m {
        display: flex;
        margin: 0 -5px; } }
    &_switch {
      align-items: stretch; } }
  &__flex &__subtitle {
    display: inline-block;
    margin: 0; }
  &__city {
    margin-left: 5px; }
  &__sublink {
    border-bottom: 1px dashed;
    color: $link;
    @include m {
      font-size: 14px; } }
  &__text {
    margin-bottom: 5px;
    @include m {
      font-size: 14px; }
    ol {
      counter-reset: count;
      li {
        counter-increment: count;
        &:before {
          content: counter(count) ". "; } } } }
  &__foot {
    padding-top: 15px;
    border-top: 1px solid $border;
    @include m {
      margin: 8px -10px 0;
      padding: 20px 10px 0; } }
  &__table {
    max-width: 405px;
    margin-bottom: 15px;
    @include m {
      max-width: 100%;
      margin-bottom: 10px; } }
  &__l {
    display: flex;
    margin-bottom: 3px;
    @include m {
      margin: 0; } }
  &__cell {
    &:nth-child(2) {
      height: 13px;
      margin: 0 5px;
      border-bottom: 1px dotted $border;
      flex: 1 0 auto;
      @include m {
        height: 16px; } }
    &:last-child {
      flex: 0 0 120px;
      @include m {
        flex: 0 0 auto; } } }
  &__payment {
    display: flex;
    @include m {
      display: block; } }
  &__info {
    margin-right: auto;
    padding: 14px 20px 0 0;
    align-self: flex-end;
    @include m {
      display: flex;
      margin: 0 0 20px;
      padding: 0;
      flex-wrap: wrap; } }
  &__label {
    @include m {
      font-weight: 700; } }
  &__total {
    font-size: 36px;
    font-weight: 700;
    @include m {
      margin-left: auto;
      font-size: 28px;
      line-height: 1; } }
  &__discount {
    display: none;
    color: $red;
    @include m {
      display: block;
      flex: 0 0 100%;
      text-align: right; } }
  &__btn {
    min-width: 212px;
    height: 65px;
    border-radius: 4px;
    font-size: 31px;
    font-weight: 700;
    @include m {
      width: 100%;
      height: 40px;
      font-size: 17px; } }
  &__fieldset {
    display: flex;
    text-align: center; }
  &__fieldset &__field {
    margin-right: -1px;
    flex: 0 0 33.33%; } }










