﻿.gsib_a .gsc-input {
  box-shadow: none !important; }

.gsc-input-box {
  box-shadow: none !important;
  height: auto !important; }

.gstl_50 {
  margin: 0 !important; }

.gsib_b {
  padding: 5px !important; }

.gsib_a {
  padding: 6px !important; }

.gsc-resultsHeader, .gsc-result .gsc-table-result {
  margin-bottom: 0 !important; }

.gsc-search-button-v2 {
  width: auto !important;
  height: auto !important; }

.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top, .cse .gsc-control-cse, .gsc-control-cse {
  @include m {
    padding: 0px !important; } }

.cse .gsc-control-cse, .gsc-control-cse {
  @include m {
    border: none !important; } }

.gsc-result-info {
  @include m {
    padding: 0 0 10px 0 !important; } }
