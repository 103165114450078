.promo {
  line-height: 1.3;
  &__center {
    padding: 0; }
  &__list {
    width: calc(100% + 100px);
    margin: 0 -50px;
    @include m {
      width: calc(100% + 20px);
      margin: 0 -10px; } }
  &__group {
    display: flex;
    width: 100%; }
  &__item {
    width: 100%;
    transition: transform 0.5s;
    height: 410px;
    background-size: cover;
    @include m {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100% !important;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
    &__wrapper {
      display: flex; }
    &__wrap {
      position: relative;
      display: flex;
      overflow: hidden;
      &:hover .promo__title {
        border-bottom: none; }
      @include m {
        height: 0px;
        padding-bottom: 64%;
        width: 100%; } }
    &__wrap:hover & {
      transform: scale(1.2); } }
  &__item_lg &__info {
    padding-left: 45px; }
  &__info {
    display: block;
    color: $white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px 50px;
    z-index: 2;
    @include m {
      padding: 15px 30px; } }
  &__title {
    margin-bottom: 2px;
    font-size: 30px;
    font-weight: 700;
    border-bottom: 1px solid $white;
    @include m {
      font-size: 19px; } }
  &__text {
    display: block;
    min-height: 40px;
    font-size: 16px;
    padding-top: 5px; }
  .owl {
    &-dots {
      display: flex;
      margin-top: 14px;
      justify-content: center;
      @include m {
        margin-top: 10px; } }
    &-dot {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      box-sizing: border-box;
      border: 1px solid $red;
      background: $white;
      border-radius: 50%;
      transition: background .2s;
      @include m {
        width: 8px;
        height: 8px;
        margin: 0 3px; }
      &:hover {
        background: #FFF; }
      &.active {
        background: $red; } } } }

