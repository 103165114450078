.gallery {
  display: flex;
  font-size: 0;
  height: 425px;
  &__thumbs {
    margin-right: 25px;
    flex: 0 0 44px; }
  &__thumb {
    display: block;
    margin-bottom: 4px;
    border: 1px solid transparent;
    transition: border-color .2s;
    &.active {
      border-color: $border; } }
  &__pic {
    max-width: 100%; }
  &__wrap {
    max-width: calc(100% - 69px);
    flex: 0 0 calc(100% - 69px); }
  &__container {
    position: relative; }
  &__item {
    padding: 0 20px; }
  &__square {
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 305px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAMAAABgOjJdAAABd1BMVEXMcVzYk33YlIDdoo/ir53isaDisqHisqLjtKLjtKTjtaTjtabktqTktqbkt6bkt6jkuajluKfluKjluanlu6vlu6zmu6vmu6zmva3mva7mvq/mvrDmwLHnvq/nv6/nwLHnwLLnwbPnwbTnw7TowbHowrPoxLXoxLboxbfpxLXpxLbpxbfpxbjpxrjpx7npyLvqxrjqyLrqybvqybzqyr3rybzry77ry7/rzcDszsLszsPt0MTt0MXu0cXu0sbu0sfu08ju08nu1crv08fv1Mnv1svw1crw1svw1szw18vw2M3w2M7w2c/w2dDw29Hx29Hx29Px3dPx3tbx3tfy3NLy3tTy39fy4dfy4dnz4Nfz4dnz493049v05d7149r15Nv15Nz15d715+D16OL16OP16uT25t/26eP26uT36+b47Ob47Of47ej57uj57+r58e768e368+/78+/79fL79fT79/X99/T9+fX9+ff9+/j9+/r//Pr///+T1o+iAAAAfXRSTlOAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgNtM5WsAAANPSURBVHjaXdPvd5tUGMBx/LG2SSuWXBrvsgrrLFgXpYtUhGIjc8EovQsSDTokboC6FhEhaKzw/PFeVuvZ2Yt77jn3eV58Xtwvw3zdAEIZfMCFYHQJ7L72FKKtIRQbGIBFBTBf/lOuhh8lpS6H5UR2y19/+rtcSkaZiaNyJT9IVwxAB63gDpeAuu3Bw+4Evu1o8NvmIVzdQhW802OaBktFMxSfN4bwQ0MEpwnwWZPhUXPFS1Uji8wtVMMul4HMXjsedwyINlpHH2CndSCxgX0hh4+FCCz8HXj8BBJegxJJAAOBblTr3UG+PsDR+qTvVRaaVHOkVwkrV6udQbF+F1MpL6xAFi7BwAE4vAMhb0CGHkDNCVcgCQyWfy8OpLhQ7/rFZ/ukIHfGRYC1/PL2UV6IUlIwnNDAgDqO8RLGvEsdjyBGKpS9QwDcOi6ye0qcqh8G6enQTaeynXqymYb3tPTyQIkzRaEO1Drwc9B5nzqmECDq6B1BzQprEDGj6Mv4RAviU82LLZXE5NiOfMWMQkWPYlUPYmabOjDOQeFbxwy+QdTBUQcrAvQxdfihbnqhabqBZZLAMZxgZtiBr1t+oI/n4alJHRxewQF/7fgK2RBwOmSsDHUXr2F/j3nj2Wxh2WRh28RzLOK+t/u9Oxs77mI8nS3GdMC8/hQA89cOExE4eTOFmB1B2RUAEE8dz5pzd0qcx45zTtqzSLNzQh9mNnHc9qYOll/BXT6BT5EP0x51sNTRpY7OXgUDnvGCse0+sSaub0/InDw6n1OF541tzzdtL7AmTIevoU8dQxSCyRFwWRPirgLl1uA/h34WRsaZH40Nbzk1yHJmOD/O9UkY6p+3gycRdWxTh4gSOOF8+IL+5KCrQbYlwdUGXwFGTJIe61FqaMGFpXm/ENX52VMnSaicJYmix6muMZvU0RZ1vxfCKUtg1jVfFPXHJgZ4G1GHpGaFMooLYxQU9nCWu0d2HspmnkpqXgxHSXFTVI8WxXpgUYd/U9QGeuGo/tqX80qR4sqU/IocOlUgnlXpQK1W+H7x51D6v6j3Wep4tai3booShRw+uSkKv1yUSDfqeocvahHFtYq8+iFr1fOuVl9sSXXV6Zf1bf7VovZeKgqJbVH/AsZAN4TsWkphAAAAAElFTkSuQmCC);
    opacity: 0;
    @include m {
      display: none; } }
  &__popup {
    display: none;
    position: absolute;
    top: -20px;
    left: calc(46% - 5px);
    right: 0;
    bottom: 30px;
    background: white;
    box-shadow: 0 6px 13.4px 2.6px rgba(80, 80, 80, 0.25);
    overflow: hidden;
    z-index: 10; }
  &__popup &__pic {
    width: 100%; }
  &.visible &__square {
    opacity: 1;
    @include m {
      opacity: 0; } }
  &.visible &__popup {
    display: block;
    @include m {
      display: none; } }
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 50%;
    font-size: 0;
    opacity: .2;
    transform: translateY(-50%);
    z-index: 4;
    &.disabled {
      visibility: hidden; }
    .icon {
      font-size: 28px; } }
  .owl-prev {
    left: -10px; }
  .owl-next {
    right: -10px; }
  .owl-dots {
    margin-top: 15px;
    padding-right: 67px;
    text-align: center; }
  .owl-dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin: 5px;
    opacity: .1;
    background: black;
    border-radius: 50%;
    &.active {
      opacity: .4; } }
  @include m {
    height: auto;
    margin-top: 60px; } }
