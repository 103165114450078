.btn {
  display: inline-block;
  padding: 6px 15px;
  background: $red;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 18px;
  color: $white;
  text-align: center;
  .icon {
    position: relative;
    top: -1px;
    fill: $white;
    &-chevron-down,
    &-chevron-right {
      margin-left: 10px;
      vertical-align: middle; }
    &-chevron-down {
      font-size: 6px; }
    &-chevron-right {
      top: -2px;
      font-size: 12px; } }
  &_sm {
    font-size: 17px; }
  &_stroke {
    background: $white;
    border: 1px solid $red;
    color: $red;
    .icon {
      fill: $red; } } }

