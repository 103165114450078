.select {
  position: relative;
  width: 260px;
  @include m {
    width: 100%; }
  &__head {
    display: flex;
    position: relative; }
  &__input {
    width: 100%;
    height: 33px;
    padding: 0 43px 0 10px;
    border: 1px solid #bd0217;
    border-radius: 4px;
    font-size: 14px; }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    font-size: 0;
    opacity: .5;
    transition: opacity .2s;
    &:hover {
      opacity: 1; }
    .icon {
      font-size: 9px; } }
  &__drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 157px;
    margin-top: 1px;
    padding: 5px 0;
    background: white;
    border: 1px solid #cfcfcf;
    border-radius: 2px;
    box-shadow: 0 6px 13.4px 2.6px rgba(80, 80, 80, 0.25);
    overflow: auto; }
  &__option {
    position: relative;
    padding: 1px 17px;
    cursor: pointer;
    &.active {
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 9px;
        width: 3px;
        height: 3px;
        background: black;
        border-radius: 50%; } } }
  &.visible {
    z-index: 999;
    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(black,.4);
      z-index: -2; } }
  &.visible &__drop {
    display: block; } }

