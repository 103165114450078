.user-avatar {
  display: flex;
  a {
    display: flex;
    img {
      border: 0;
      border-radius: 50px;
      height: 34px;
      margin: auto; } } }

.newOverlay {
  display: none;
  background: rgba(48, 48, 48, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  text-align: left; }

.newOverlay-block {
  width: 385px;
  position: absolute;
  top: 20%;
  left: 50%;
  margin: 0 0 0 -192px;
  background: #fff;
  border-bottom: 4px solid #bd0217; }

.newOverlay-header {
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 0 #f0f0f0;
  .h2title {
    font-family: proxima-nova, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    color: #303030;
    text-shadow: none;
    padding: 8px 0 5px 15px;
    margin: 0;
    font-weight: 700;
    line-height: inherit;
    height: 30px; } }

.close {
  float: right;
  width: 29px;
  height: 29px;
  background: #f2f2f2;
  position: relative;
  display: block;
  z-index: auto; }

.i-close {
  display: block;
  z-index: auto;
  width: 8px;
  height: 8px;
  background: url(../img/i-close.png) no-repeat;
  background-size: cover;
  position: absolute;
  top: 12px;
  left: 11px; }

.form-footer {
  border-top: 1px solid #e4e4e4;
  background: #f7f7f7;
  padding: 13px;
  .btn-login {
    padding: 9px 0 7px;
    width: 110px;
    text-align: center; } }

.ffooter-right {
  float: right;
  font-size: 12px;
  color: #999;
  padding: 6px 0 0;
  a {
    font-size: 13px;
    font-weight: 700;
    color: #bd0217; } }

.legend {
  border-top: 1px solid #f0f0f0;
  margin: 22px 13px 0;
  text-align: center;
  strong {
    font-family: proxima-nova, sans-serif;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: #6c6c6c;
    position: relative;
    top: -10px;
    display: inline-block;
    padding: 0 19px;
    background: #fff; } }

.form-login {
  margin: 0 13px 5px;
  label {
    display: block;
    font-size: 12px;
    margin: 0 0 4px; } }

.form-el {
  padding: 0 0 15px;
  input {
    padding: 0;
    background: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: inset 0 2px 0 #efefef;
    padding: 5px 9px 6px;
    width: 100%;
    height: 36px; } }

.btn-reg {
  margin: 0 auto;
  width: 225px !important; }

.btn-exit {
  float: left;
  display: block;
  background: #bd0217;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 7px 0 8px;
  width: 69px;
  text-align: center;
  border: 0; }

.form-socbtns {
  a {
    float: left;
    margin: 0 0 0 14px; } }

.form-socbtns.c {
    display: flex; }

.btn-login {
  display: block;
  background: #bd0217;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  font-family: proxima-nova;
  text-transform: uppercase;
  padding: 9px 20px 8px 13px;
  border: 0; }
