.head {
  position: relative;
  margin-bottom: 25px;
  @include m {
    margin-bottom: 15px;
    padding: 10px 0 0 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  &__burger {
    display: none;
    position: absolute;
    top: 17px;
    left: 0;
    font-size: 0;
    @include m {
      display: block; }
    .icon {
      font-size: 14px; } }
  &__title,
  &__buy {
    display: inline;
    font-size: 36px;
    font-weight: 700;
    @include m {
      font-size: 23px; } }
  &__buy {
    margin: 0 10px 0 15px;
    color: $gray-dark;
    @include m {
      margin: 0 0 0 5px; } }
  &__city {
    display: inline-block; } }

