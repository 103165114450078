.cart {
  padding: 25px 0 60px;
  background: #faf9f9;
  @include m {
    padding: 12px 0 25px;
    background: white; }
  &__title {
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 700;
    @include m {
      margin-bottom: 10px;
      font-size: 24px; } }
  &__layout {
    display: flex;
    @include w {
      display: block; } }
  &__col {
    flex: 0 0 calc(100% - 690px);
    &:last-child {
      margin-left: 40px;
      flex: 0 0 650px;
      @include w {
        margin: 50px 0 0; }
      @include m {
        margin-top: 8px; } } }
  &__wrap {
    background: white;
    border-radius: 4px;
    border: 1px solid $border;
    @include m {
      margin: 0 -10px;
      border-radius: 0;
      border-width: 0 0 1px;
      box-shadow: 0 8px 0 0 #f8f9fd; } }
  &__head {
    padding: 14px 20px 7px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid $border;
    @include m {
      padding: 0 10px 12px; } }
  &__row {
    display: flex;
    position: relative;
    min-height: 112px;
    padding: 15px 20px 20px;
    border-bottom: 1px solid $border;
    @include m {
      padding: 15px 10px 10px;
      flex-wrap: wrap; } }
  &__cell {
    &:first-child {
      margin-right: 17px;
      flex: 0 0 70px;
      @include m {
        margin: 0 15px 10px 0; } }
    &:nth-child(2) {
      margin-right: 20px;
      flex: 0 1 auto;
      @include m {
        margin: 0 0 5px;
        flex: 0 0 calc(100% - 85px); } }
    &:nth-child(3) {
      margin-left: auto;
      flex: 0 0 90px;
      @include m {
        display: flex;
        margin: 0;
        flex: 0 0 175px;
        align-self: center;
        align-items: center; } }
    &:last-child {
      margin-left: 15px;
      flex: 0 0 100px;
      text-align: right;
      @include m {
        margin-left: 15px;
        flex: 0 0 calc(100% - 190px);
        align-self: center; } } }
  &__preview {
    display: block;
    font-size: 0;
    text-align: center; }
  &__pic {
    max-width: 70px;
    max-height: 70px; }
  &__link {
    font-size: 19px;
    font-weight: 700;
    line-height: 1.2; }
  &__options {
    margin-top: 5px;
    @include m {
      margin-top: 7px; } }
  &__amount {
    position: relative;
    top: -5px;
    @include m {
      top: 0;
      order: 2; } }
  &__price {
    font-size: 19px;
    font-weight: 700;
    @include m {
      font-size: 21px; } }
  &__discount {
    font-size: 16px;
    color: $red;
    @include m {
      font-size: 14px; } }
  &__remove {
    display: block;
    width: 30px;
    height: 20px;
    margin: 0 auto;
    font-size: 0;
    opacity: .8;
    @include m {
      margin: 0 auto 0 0;
      order: 1; }
    .icon {
      font-size: 13px;
      color: #7f7f7f;
      @include m {
        font-size: 18px; } } }
  &__foot {
    display: flex;
    padding: 10px 20px 15px;
    @include m {
      padding: 10px 10px 15px;
      align-items: center; } }
  &__info {
    margin-right: 20px;
    font-size: 19px; }
  &__total {
    margin-left: auto;
    text-align: right; }
  &__total &__discount {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
    @include m {
      display: none; } }
  &__total &__price {
    font-size: 30px;
    @include m {
      font-size: 23px; } } }
