.prod {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 50px;
  border-bottom: 1px solid $border;
  @include m {
    margin-bottom: 25px;
    padding: 0;
    border: none;
    flex-direction: column; }
  &__col {
    &:first-child {
      max-width: 46%;
      padding-right: 55px;
      flex: 0 0 46%;
      @include m {
        max-width: 100%;
        padding: 0 0 20px;
        flex: 0 0 100%;
        order: 1; } }
    &:nth-child(2) {
      padding-right: 55px;
      flex: 0 0 30%;
      @include m {
        padding: 0;
        flex: 0 0 100%;
        order: 3; } }
    &:last-child {
      flex: 0 0 24%;
      @include m {
        position: relative;
        margin-bottom: 8px;
        flex: 0 0 100%;
        order: 2; } } }
  &__options {
    margin-bottom: 5px;
    @include m {
      display: none; } }
  &__params {
    margin-bottom: 17px; }
  &__rating {
    margin-bottom: 25px;
    @include m {
      margin-bottom: 22px; } }
  &__selector {
    margin-bottom: 20px; }
  &__city {
    display: none;
    position: absolute;
    top: 47px;
    left: 0;
    right: 0;
    background: #f8f9fd;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    font-size: 17px;
    color: black;
    @include m {
      display: block;
      padding: 8px 15px; }
    .icon {
      fill: black; } } }
