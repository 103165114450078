.ttip {
  position: relative;
  &:hover,
  &.visible {
    &:before,
    &:after {
      display: block; } }
  &:before,
  &:after {
    display: none;
    position: absolute;
    left: 50%; }
  &:before {
    display: none;
    content: attr(data-tooltip);
    top: calc(100% + 3px);
    width: 170px;
    padding: 3px;
    background: #707070;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
    color: white;
    transform: translateX(-50%);
    z-index: 6; }
  &:after {
    content: '';
    bottom: -4px;
    margin-left: -7px;
    @include arr(14,7,#707070,t); } }
