.breadcrumbs {
  display: flex;
  padding: 10px 0 15px;
  flex-wrap: wrap;
  font-size: 12px;
  color: $gray;
  &__item {
    padding: 5px 0;
    @include nl {
      &:after {
        content: '/';
        margin: 0 12px; } }
    &-last {
      &:after {
        content: ''!important;
        margin: 0 0 0 0!important; } } }
  &__link {
    color: $gray;
    transition: color .2s;
    &:hover {
      color: $red; } } }
