.points {
  @include d {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 50px;
    background: rgba(black,.4);
    overflow: auto;
    z-index: 999;
    &.visible {
      display: block; } }
  &__container {
    @include d {
      width: 100%;
      max-width: 100%;
      width: 1125px;
      margin: auto;
      background: white;
      border: 1px solid #cfcfcf;
      border-radius: 2px;
      box-shadow: 0 6px 13.4px 2.6px rgba(80, 80, 80, 0.25); } }
  &__head {
    display: flex;
    border-bottom: 1px solid #CFCFCF;
    @include m {
      margin-bottom: 8px;
      border: none; } }
  &__title {
    padding: 14px 20px;
    font-size: 18px;
    font-weight: 700;
    @include m {
      padding: 7px 10px; } }
  &__close {
    width: 52px;
    height: 52px;
    margin-left: auto;
    font-size: 0;
    @include m {
      width: 36px;
      height: 36px; }
    .icon {
      font-size: 16px;
      fill: black; } }
  &__body {
    @include d {
      position: relative; } }
  &__nav {
    display: none;
    @include m {
      display: block;
      &.hidden {
        display: none; } } }
  &__toggle {
    display: none;
    margin: 0 10px 10px;
    color: $link;
    &.visible {
      display: block; } }
  &__icon,
  &__type {
    display: inline-block;
    vertical-align: middle; }
  &__icon {
    margin-right: 12px;
    font-size: 0;
    .icon {
      font-size: 18px;
      fill: $link; } }
  &__type {
    font-size: 17px;
    text-decoration: underline; }
  &__wrap {
    @include d {
      height: 615px;
      padding-left: 55%;
      overflow: auto; }
    &.hidden {
      display: none; } }
  &__map {
    background: gray;
    @include d {
      position: absolute;
      top: 0;
      left: 0;
      width: 55%;
      height: 100%; }
    @include m {
      display: none;
      height: 730px;
      &.visible {
        display: block; } } }
  &__group {
    padding: 20px 30px 10px;
    @include m {
      padding: 0 10px 5px; } }
  &__stats {
    margin-bottom: 10px;
    @include m {
      font-size: 14px; } }
  &__filters {
    display: flex;
    flex-wrap: wrap; }
  &__filter {
    display: flex;
    margin: 0 10px 10px 0;
    padding: 0 6px 1px 8px;
    background: #fcf0f1;
    border: 1px solid #ca9ba1;
    border-radius: 2px;
    align-items: center;
    @include m {
      padding: 8px; }
    &_blue {
      border: 1px solid rgba(#287c9f,.3);
      border-radius: 2px;
      background: transparent;
      color: #287C9F; }
    .icon {
      margin: 1px 0 0 5px;
      font-size: 9px;
      opacity: .3; } }
  &__list {
    border-top: 1px solid #CFCFCF;
    @include m {
      display: none;
      &.visible {
        display: block; } } }
  &__item {
    display: block;
    padding: 15px 30px;
    color: black;
    @include m {
      padding: 15px 10px; }
    &:not(:last-child) {
      border-bottom: 1px solid #CFCFCF; }
    &_single {
      padding: 0 30px 30px;
      @include m {
        padding: 15px 10px 20px;
        border-top: 1px solid #CFCFCF; } } }
  &__text {
    max-width: 300px;
    font-size: 16px;
    line-height: 1.375;
    @include m {
      font-size: 17px; }
    & + & {
      margin-top: 10px; }
    &_icon {
      position: relative;
      margin-bottom: 20px;
      padding-left: 20px;
      .icon {
        position: absolute;
        top: 4px;
        left: 0;
        font-size: 11px;
        fill: $red; } } }
  &__back {
    position: relative;
    width: 138px;
    height: 30px;
    margin: 15px 30px 20px;
    color: $red;
    @include m {
      margin: 0 10px 15px; }
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 30px;
      fill: rgba(#bd0217,.5); } }
  &__btn {
    cursor: pointer;
    margin-top: 10px; }
  &__desc {
    padding: 15px 30px;
    font-size: 16px;
    @include m {
      padding: 15px 10px;
      font-size: 17px; } }
  &__pic {
    display: block;
    max-width: 100%; } }
