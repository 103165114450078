.content {
  line-height: 1.43;
  font-size: 17px;
  @include m {
    font-size: 17px;
    line-height: 1.3; }
  p {
    margin-bottom: 10px; }
  a {
    border-bottom: 1px solid rgba($red,.5);
    color: $red; }
  &_border {
    padding-top: 10px;
    border-top: 1px solid #dadada; } }
