.options {
  font-size: 19px;
  &__row {
    display: flex; }
  &__col {
    flex: 0 0 50%; }
  &__title {
    margin-bottom: 10px; }
  &__list {
    display: flex;
    flex-wrap: wrap; }
  &__list_memory &__check {
    margin: 0 10px 10px 0; }
  &__list_memory &__input_active {
    background-color: #faf1f2 !important;
    border: 1px solid #f57b8a !important;
    box-shadow: 0 0 5.8px 0.2px rgba(189, 2, 23, 0.18);
    font-weight: 700; }
  &__list_memory &__value {
    min-width: 70px;
    height: 32px;
    padding: 0 5px;
    background: white;
    border: 1px solid $border;
    border-radius: 2px;
    text-align: center;
    line-height: 32px; }
  &__list_memory &__check.disabled &__value {
    &:before {
      transform: translate(-50%,-50%) rotate(23deg); }
    &:after {
      transform: translate(-50%,-50%) rotate(68deg); } }
  &__list_colors &__check {
    margin: 3px 6px 10px 0; }
  &__list_colors &__value_active {
    box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(#f57b8a,.5); }
  &__list_colors &__value {
    min-width: 26px;
    min-height: 26px;
    border-radius: 4px; }
  &__check {
    user-select: none;
    cursor: pointer; }
  &__check.disabled &__value {
    cursor: default;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      top: 50%;
      left: 50%;
      background: black; }
    &:before {
      width: 100px;
      height: 1px;
      transform: translate(-50%,-50%) rotate(45deg); }
    &:after {
      width: 1px;
      height: 100px;
      transform: translate(-50%,-50%) rotate(45deg); } }
  &__check.disabled.white &__value {
    &:before,
    &:after {
      background: white; } }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  &__value {
    display: block;
    position: relative;
    transition: all .2s;
    overflow: hidden;
    &[style="background-color: white;"],
    &[style="background-color: white"],
    &[style="background-color: #ffffff;"],
    &[style="background-color: #ffffff"],
    &[style="background-color: #fff"] {
      border: 1px solid $border; } }
  &_sm &__title {
    margin-bottom: 5px; }
  &_sm &__list_colors &__check {
    margin: 0 5px 5px 0;
    @include m {
      margin: 0 8px 8px 0; } }
  &_sm &__list_colors &__value {
    min-width: 16px;
    min-height: 16px;
    @include m {
      min-width: 19px;
      min-height: 19px; } }
  &_sm &__list_colors &__input:checked + &__value {
    box-shadow: 0 0 0 1px white, 0 0 0 2px rgba(#f57b8a,.5); } }

