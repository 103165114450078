.experts {
  &__head {
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 3px solid $red;
    align-items: center;
    justify-content: space-between; }
  &__title {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase; }
  &__help {
    margin: 0 5px 0 auto; }
  &__table {
    display: table;
    width: 100%;
    font-weight: 700; }
  &__row {
    display: table-row; }
  &__cell {
    display: table-cell;
    padding-top: 4px;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    &:last-child {
      padding-left: 10px;
      text-align: right; } } }
