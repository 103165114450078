.city {
  display: inline-block;
  position: relative;
  z-index: 6;
  &__head {
    position: relative;
    border-bottom: 2px dashed;
    font-size: 30px;
    font-weight: 700;
    color: $link;
    line-height: 1;
    z-index: 4; }
  &__wrap {
    display: none;
    position: absolute;
    top: -10px;
    left: -20px;
    width: 416px;
    padding: 55px 20px 25px;
    background: white;
    border: 1px solid $border;
    border-bottom: 2px solid $red;
    border-radius: 2px;
    box-shadow: 0 6px 13.4px 2.6px rgba(80, 80, 80, 0.25);
    z-index: 2; }
  &__list {
    margin-bottom: 5px;
    columns: 2 20px; }
  &__item {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 12px;
    font-size: 16px;
    color: black;
    &.active {
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: -10px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: black; } } }
  &__search {
    position: relative;
    .icon {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 19px;
      fill: #ccc;
      pointer-events: none; } }
  &__input {
    width: 100%;
    height: 38px;
    padding: 0 10px 0 40px;
    background: $bg;
    border: 1px solid $border;
    border-radius: 2px; }
  &__select {
    display: none;
    top: -1px;
    @include m {
      position: absolute;
      top: -3px;
      left: 0; } }
  &:hover {
    z-index: 60; }
  &:not(&_order):hover &__head {
    border-color: transparent;
    color: black; }
  &:not(&_order):hover &__wrap {
    display: block; }
  &_order {
    padding: 7px 0;
    margin: -7px 5px;
    @include m {
      position: static; } }
  &_order &__head {
    border-width: 0 0 1px;
    font-size: 20px;
    cursor: pointer; }
  &_order.visible {
    padding: 0;
    z-index: 99; }
  &_order.visible &__head {
    display: none;
    @include m {
      display: block; } }
  &_order.visible &__select {
    display: block; } }

