.quality {
  font-size: 19px;
  @include m {
    font-size: 17px; }
  &__item {
    position: relative;
    padding-left: 30px;
    @include nl {
      margin-bottom: 10px; }
    @include m {
      padding-left: 26px; } }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    .icon {
      font-size: 18px;
      &-plus-circle {
        fill: #006f00; }
      &-minus-circle {
        fill: $red; } } }
  &__title {
    font-weight: 700; } }
