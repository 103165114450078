html,
body {
  height: 100%; }
body {
  overflow-y: scroll;
  min-width: 1024px;
  @include f;
  font-size: 14px;
  line-height: 1.3;
  @include m {
    min-width: 320px;
    font-size: 17px;
    overflow-y: auto; } }
body,
button,
input,
textarea,
select {
  @include f;
  font-size: 14px; }
button {
  background: transparent; }
a {
  color: $link;
  text-decoration: none; }

.bold {
  font-weight: 700; }

.red {
  color: $red; }
.green {
  color: $green; }

.small {
  font-size: 14px; }

.hidden {
  display: none; }

