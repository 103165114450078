.variants {
  display: none;
  z-index: 6;
  position: absolute;
  top: 0;
  width: 100%;
  @include m {
    display: block; }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black,.3); }
  &__wrap {
    display: flex;
    width: calc(100% + 20px);
    position: relative;
    margin: 0 -10px 15px;
    z-index: 2; }
  &__item {
    position: relative;
    flex-grow: 1; }
  &__item + &__item {
    border-left: 1px solid #e1e1e1; }
  &__item_lvls &__option {
    padding: 8px 20px; }
  &__item.visible &__overlay {
    display: block; }
  &__item.visible &__head {
    background: white; }
  &__item.visible &__arrow {
    .icon {
      transform: rotate(180deg); } }
  &__item.visible &__drop {
    display: block; }
  &__head {
    display: flex;
    position: relative;
    height: 40px;
    padding: 0 10px;
    background: #f8f9fd;
    border: solid #e1e1e1;
    border-width: 1px 0;
    align-items: center;
    justify-content: center;
    z-index: 2; }
  &__color {
    position: relative;
    top: -1px;
    margin-right: 6px;
    width: 15px;
    height: 15px;
    border: 1px solid #d7d7d7;
    border-radius: 2px; }
  &__arrow {
    margin-left: 6px;
    font-size: 0;
    .icon {
      font-size: 8px; } }
  &__drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    width: 140px; }
  &__group.disabled &__option {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      top: 50%;
      left: 50%;
      background: black; }
    &:before {
      width: 250px;
      height: 1px;
      transform: translate(-50%,-50%) rotate(15deg); }
    &:after {
      width: 1px;
      height: 250px;
      transform: translate(-50%,-50%) rotate(75deg); } }
  &__drop_center &__option {
    justify-content: center; }
  &__group {
    &.disabled {
      overflow: hidden; }
    @include nl {
      border-bottom: 1px solid #e1e1e1; } }
  &__option {
    display: flex;
    position: relative;
    padding: 8px 10px;
    align-items: center;
    .icon {
      position: absolute;
      top: 15px;
      right: 10px;
      font-size: 8px;
      opacity: .3; }
    &.active {
      .icon {
        transform: rotate(180deg); } }
    &.selected {
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        top: 16px;
        left: 10px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: black; } } }
  &__list {
    display: none;
    padding: 0 20px 13px;
    &.visible {
      @include m {
        display: block; } } }
  &__switch {
    @include nl {
      margin-bottom: 10px; } }
  &__name {
    min-width: 90px; } }











