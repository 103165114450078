.dt {
  position: relative;
  max-width: 406px;
  z-index: 10;
  &__head {
    display: flex;
    height: 33px;
    padding: 0 14px;
    border: 1px solid $border;
    border-radius: 4px;
    align-items: center;
    user-select: none;
    cursor: pointer; }
  &__title {
    max-width: calc(100% - 30px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  &__arrow {
    font-size: 0;
    margin-left: auto;
    font-size: 0;
    .icon {
      font-size: 5px; } }
  &__drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 1px;
    padding: 8px 9px;
    background: white;
    border-radius: 2px;
    border: 1px solid $border;
    box-shadow: 0 6px 13.4px 2.6px rgba(80, 80, 80, 0.25);
    z-index: 50;
    @include m {
      left: -10px;
      right: -10px; } }
  &__group {
    display: flex;
    flex-wrap: wrap; }
  &__group_time &__switch {
    padding: 0 4px 4px 0;
    flex: 0 0 33.33%; }
  &__switch {
    flex: 0 0 14.28%; }
  &__subtitle {
    margin: 10px 0 5px 5px;
    font-weight: 700; }
  &.visible &__arrow {
    transform: rotate(180deg); }
  &.visible &__drop {
    display: block; } }

