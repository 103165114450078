.comments {
  max-width: 980px;
  border: 1px solid $border;
  &__section {
    position: relative;
    padding: 25px 20px 5px;
    box-shadow: inset 0 90px 60px -60px rgba(0,0,0,0.03);
    &:first-child {
      padding: 20px 20px 30px; } }
  &__title {
    margin: 0 0 20px;
    font-weight: 700; }
  &__form {
    max-width: 740px; }
  &__field {
    margin-bottom: 20px; }
  &__textarea {
    display: block;
    width: 100%;
    padding: 7px 10px;
    border: 1px solid $border;
    border-radius: 2px;
    box-shadow: inset 0 0 5px 0 rgba(0,0,0,0.12);
    resize: none; }
  &__btn {
    padding: 9px 20px 8px;
    border-radius: 0;
    font-size: 10px;
    text-transform: uppercase; }
  &__all {
    font-weight: 700;
    color: $red; }
  &__counter {
    margin-left: 5px;
    font-size: 10px;
    vertical-align: top; }
  &__item {
    display: flex;
    padding: 25px 0;
    @include nl {
      border-bottom: 1px solid rgba($border,.6); } }
  &__wrap {
    flex: 0 0 calc(100% - 200px); }
  &__wrap &__form {
    margin-top: 25px; }
  &__content {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px dotted $border; }
  &__meta {
    font-size: 12px; }
  &__author {
    display: flex;
    margin-left: 20px;
    flex: 0 0 180px; }
  &__ava {
    margin-right: 20px;
    flex: 0 0 30px; }
  &__pic {
    display: block;
    width: 100%; }
  &__divider {
    margin: 0 10px; }
  &__year {
    color: $gray; }
  &__action {
    color: $red; } }












