.review {
  &__row {
    display: flex; }
  &__col {
    &:first-child {
      margin-right: 55px;
      flex: 0 0 215px; }
    &:nth-child(2) {
      flex: 0 0 calc(100% - 410px); }
    &:last-child {
      margin-left: 55px;
      flex: 0 0 84px; } }
  &__photos {
    margin-bottom: 10px; }
  &__photo {
    display: block;
    margin-bottom: 7px; }
  &__pic {
    display: block;
    width: 100%; }
  &__link {
    display: block;
    position: relative;
    &:before {
      content: '+';
      position: absolute;
      top: 0;
      left: -10px; } } }
