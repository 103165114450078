.buy {
  border: 1px solid $border;
  background: #fdfcfc;
  @include m {
    border: none; }
  &__btn {
    width: calc(100% + 2px);
    margin: -1px;
    padding: 2px 15px 3px;
    font-size: 22px;
    font-weight: 700;
    &_non-sale {
      background: #cdcdcd;
      color: black; }
    @include m {
      margin: 0;
      padding: 9px 15px;
      font-size: 17px; } }
  &__wrap {
    padding: 10px 10px 15px;
    @include m {
      margin-top: 45px; } }
  &__link {
    display: table;
    margin: 0 auto 10px;
    font-size: 19px;
    @include m {
      margin: 0 0 8px;
      font-size: 17px; } }
  &__service {
    display: flex;
    font-size: 19px; }
  &__icon {
    display: flex;
    height: 24px;
    margin-right: 12px;
    flex: 0 0 24px;
    align-items: center;
    justify-content: center; }
  &__text {
    padding: 2px 0;
    flex: 0 0 calc(100% - 36px); }
  &__icon {
    .icon {
      fill: $red;
      &-check-circle {
        font-size: 18px; }
      &-delivery {
        font-size: 24px; }
      &-security {
        font-size: 20px; } } } }


