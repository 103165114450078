.rating {
  display: flex;
  align-items: center;
  &__stars {
    display: flex;
    margin-right: 9px;
    font-size: 0;
    .icon {
      font-size: 16px;
      fill: $yellow;
      @include m {
        font-size: 14px; } } }
  &__value {
    position: relative;
    top: 2px;
    margin-right: 14px; }
  &__link {
    position: relative;
    top: 2px;
    font-size: 16px;
    @include m {
      font-size: 17px; } }
  &_section &__stars {
    .icon {
      font-size: 18px; } }
  &_section &__value {
    margin: auto;
    font-size: 17px; }
  &_red &__stars {
    .icon {
      fill: $red; } } }
