.feedback {
  &__item {
    padding: 10px 0 20px;
    border-top: 1px solid $border; }
  &__title {
    margin-bottom: 3px;
    font-weight: 700; }
  &__head {
    display: flex;
    margin-bottom: 10px; }
  &__wrap {
    margin-right: 10px; }
  &__meta {
    display: flex;
    margin-bottom: 3px;
    font-size: 14px; }
  &__user {
    font-weight: 700; }
  &__date {
    margin-left: 10px;
    color: $gray; }
  &__stars {
    font-size: 0;
    .icon {
      font-size: 14px;
      fill: $yellow; } }
  &__actions {
    margin-left: auto;
    padding-top: 3px; }
  &__action {
    width: 49px;
    height: 32px;
    margin-left: 4px;
    border: 1px solid;
    border-radius: 3px;
    font-size: 14px; }
  &__content {
    line-height: 1.2; }
  &__btn {
    width: 100%;
    @include nl {
      margin-bottom: 8px; } } }

