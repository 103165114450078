.toggle {
  display: inline-block;
  font-size: 14px;
  &__title,
  &__arrow {
    vertical-align: middle; }
  &__title {
    display: inline;
    border-bottom: 1px dashed;
    color: $link; }
  &__arrow {
    display: inline-block;
    margin-left: 5px;
    .icon {
      font-size: 6px;
      fill: $link; } }
  &.active &__arrow {
    .icon {
      transform: rotate(180deg); } } }
