.top-page-button {
  position: fixed;
  z-index: 3;
  right: 0;
  bottom: 102px;
  display: none;
  width: 120px;
  height: 82px;
  background: rgba(230, 230, 230, 0.6);
  cursor: pointer;
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0px;
    height: 100%; }
  @include m {
    bottom: 141px;
    width: 46px;
    height: 46px; }
  .icon-chevron-up {
    width: 32px;
    height: 18px;
    margin-top: 32px;
    @include m {
      margin-top: 15px; } } }
