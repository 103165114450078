.icon-arrow-menu-down {
  width: 1.5em;
  height: 1em;
  fill: #000;
}
.icon-arrow-menu {
  width: 2.25em;
  height: 1em;
  fill: #000;
}
.icon-arrow-right {
  width: 0.67em;
  height: 1em;
  fill: #000;
}
.icon-back {
  width: 4.58em;
  height: 1em;
  fill: #BD0217;
}
.icon-burger {
  width: 1.44em;
  height: 1em;
  fill: initial;
}
.icon-cart {
  width: 1.21em;
  height: 1em;
  fill: #595959;
}
.icon-check-circle {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-check-square {
  width: 0.88em;
  height: 1em;
  fill: initial;
}
.icon-chevron-down {
  width: 1.75em;
  height: 1em;
  fill: #000;
}
.icon-chevron-right {
  width: 0.57em;
  height: 1em;
  fill: #000;
}
.icon-chevron-up {
  width: 1.83em;
  height: 1em;
  fill: #4c4c4c;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-delivery {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-error {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-left {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-list {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-m-left {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-m-right {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-map {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-minus-circle {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-next-hover {
  width: 0.18em;
  height: 1em;
  fill: #000;
}
.icon-next {
  width: 0.17em;
  height: 1em;
  fill: #000;
}
.icon-phone {
  width: 0.91em;
  height: 1em;
  fill: initial;
}
.icon-plus-circle {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-prev-hover {
  width: 0.18em;
  height: 1em;
  fill: #000;
}
.icon-prev {
  width: 0.17em;
  height: 1em;
  fill: #000;
}
.icon-right {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: #000;
}
.icon-security {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-square {
  width: 0.88em;
  height: 1em;
  fill: initial;
}
.icon-star-empty {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-star {
  width: 1em;
  height: 1em;
  fill: #38447E;
}
.icon-success {
  width: 1.06em;
  height: 1em;
  fill: #BD0217;
}
.icon-trash {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-zoom-in {
  width: 1em;
  height: 1em;
  fill: initial;
}
