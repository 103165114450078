.categories {
  position: relative;
  @include m {
    margin: 0 -10px; }
  &__item {
    display: block;
    padding: 0 5px;
    box-sizing: border-box;
    text-align: center;
    background-position: 50% 50%;
    @include m {
      padding: 0 10px 10px; } }
  &__item:hover &__title {
      text-decoration: none; }
  &__item:hover &__pic {
      transform: scale(1.2); }
  &__preview {
    display: flex;
    height: 150px;
    padding: 10px 0;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    @include m {
      height: 75px; } }
  &__pic {
    transition: transform 0.5s;
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%; }
  &__title {
    word-wrap: break-word;
    font-size: 20px;
    color: #000;
    text-decoration: underline;
    line-height: 1.1;
    @include m {
      font-size: 14px; } }
  .owl {
    &-prev,
    &-next {
      position: absolute;
      top: 5px;
      width: 38px;
      height: 136px;
      font-size: 0;
      transition: background .2s;
      &:hover {
        background: #F5F5F5;
        .icon {
          display: none; }
        .icon-prev-hover,
        .icon-next-hover {
          display: inline-block; } }
      .icon {
        font-size: 119px; }
      .icon-prev-hover,
      .icon-next-hover {
        display: none;
        margin-right: 1px; } }
    &-prev {
      left: -42px; }
    &-next {
      right: -42px; } } }
