.selector {
  position: relative;
  padding-bottom: 1px;
  z-index: 4;
  &:hover &__head {
    background: white; }
  &:hover &__arrow {
    transform: rotate(180deg); }
  &__head {
    display: flex;
    padding: 2px 14px 3px 10px;
    background-color: $bg;
    border: 1px solid $border-dark;
    border-radius: 2px;
    align-items: center;
    transition: background .2x;
    &.active {
      background: white; } }
  &__values {
    max-width: calc(100% - 20px);
    font-size: 22px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  &__arrow {
    margin-left: auto;
    font-size: 0;
    .icon {
      font-size: 7px; } }
  &__drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid $border-dark;
    border-radius: 2px;
    box-shadow: 0 6px 13.4px 2.6px rgba(80, 80, 80, 0.25); }
  &__group {
    position: relative;
    @include nl {
      border-bottom: 1px solid $border; } }
  &__group:hover &__option {
    background: $bg; }
  &__group:hover &__list {
    display: block; }
  &__option {
    position: relative;
    padding: 7px 30px 7px 22px;
    background: white;
    font-size: 16px;
    transition: background .2s;
    z-index: 2;
    &.selected {
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        top: 14px;
        left: 10px;
        width: 4px;
        height: 4px;
        background: black;
        border-radius: 50%; } }
    .icon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 12px;
      opacity: .5; } }
  &__list {
    display: none;
    position: absolute;
    top: -1px;
    left: 100%;
    width: 194px;
    max-height: 173px;
    padding: 10px;
    background: white;
    border: 1px solid $border-dark;
    border-radius: 0 2px 2px 2px;
    box-shadow: 0 6px 13.4px 2.6px rgba(80, 80, 80, 0.25);
    font-size: 14px;
    overflow: auto; }
  &__switch {
    @include nl {
      margin-bottom: 6px; } }
  &:hover &__drop {
    display: block; } }









