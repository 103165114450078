.res {
  padding: 50px 0;
  background: #faf9f9;
  @include m {
    padding: 0; }
  &__wrap {
    max-width: 642px;
    margin: 0 auto;
    padding: 30px 30px 40px;
    background: white;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    @include m {
      margin: 0 -10px;
      padding: 5px 10px 70px;
      border-radius: 0;
      border: none; } }
  &__title {
    display: flex;
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 700;
    justify-content: center;
    @include m {
      margin-bottom: 5px;
      font-size: 23px;
      justify-content: flex-start; }
    .icon {
      margin: 7px 10px 0 0;
      font-size: 18px;
      fill: $red; } }
  &__info {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    @include m {
      font-size: 17px;
      font-weight: 400; }
    p {
      @include m {
        display: inline; } } }
  &__row {
    display: flex;
    margin-bottom: 20px;
    @include m {
      margin-bottom: 15px; }
    &_end {
      align-items: flex-end;
      &-hide {
        overflow: hidden;
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }
      &-show {
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        max-height: 1000px;
        overflow: hidden; }
      @include m {
        flex-direction: column; } } }
  &__row_end &__col {
    &:first-child {
      @include m {
        flex: 0 0 100%;
        order: 2; } }
    &:last-child {
      @include m {
        flex: 0 0 100%;
        order: 1; } } }
  &__col {
    @include m {
      width: 100%; }
    &:first-child {
      padding: 2px 20px 0 0;
      flex: 0 0 27.5%;
      @include m {
        flex: 0 0 41.5%;
        font-size: 14px; } }
    &:last-child {
      font-size: 16px;
      flex: 0 0 72.5%;
      @include m {
        flex: 0 0 58.5%;
        font-size: 17px; } }
    &.bold {
      @include m {
        font-size: 17px; } } }
  &__toggle {
    margin-top: 10px;
    @include m {
      font-size: 17px; } }
  &__map {
    text-align: center;
    @include m {
      margin-top: 10px;
      text-align: left; } }
  &__preview {
    display: block;
    max-width: 100%;
    margin-bottom: 3px;
    @include m {
      display: none; } }
  &__link {
    @include m {
      font-size: 17px; } }
  &__divide {
    margin: 10px 0;
    border: 1px solid #ebebeb;
    background: #f8f9fd;
    border-width: 1px 0 0;
    @include m {
      height: 10px;
      margin: 35px -10px 10px;
      border-width: 1px 0; } }
  &__price {
    font-size: 30px;
    font-weight: 700;
    @include m {
      font-size: 28px;
      text-align: right; } }
  &__text {
    &_center {
      max-width: 375px;
      margin: 0 auto;
      text-align: center;
      @include m {
        text-align: left; } } }
  &__foot {
    margin-top: 25px;
    text-align: center;
    @include m {
      text-align: left; } }
  &__btn {
    min-width: 170px;
    margin: 0 8px;
    @include m {
      min-width: 100%;
      margin: 0 0 10px; } } }


