.switch {
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  &__input:checked + &__content {
    &:before {
      background: $red;
      border-color: $red; } }
  &__content {
    display: block;
    height: 100%;
    padding-left: 20px;
    transition: all .2s;
    @include m {
      padding: 2px 0 0 28px;
      font-size: 14px; }
    &:before,
    &:after {
      content: '';
      position: absolute; }
    &:before {
      top: 2px;
      left: 0;
      width: 13px;
      height: 13px;
      border: 1px solid $border;
      background: white;
      border-radius: 2px;
      transition: backgrpund .2s, border-color .2s;
      @include m {
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 0; } }
    &:after {
      top: 3px;
      left: 4px;
      width: 5px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      @include m {
        top: 1px;
        left: 6px;
        width: 8px;
        height: 14px; } } }
  &__type {
    border-bottom: 1px dashed $link;
    font-size: 19px;
    color: $link;
    transition: all .2s;
    @include m {
      font-size: 17px; } }
  &__note {
    display: block;
    color: #7f7f7f; }
  &__day {
    font-size: 16px;
    font-weight: 700;
    color: $link;
    text-transform: uppercase;
    transition: color .2s; }
  &__date {
    display: block;
    color: #7f7f7f;
    text-align: center;
    @include m {
      font-size: 12px; } }
  &__time {
    border-bottom: 1px dashed;
    color: $link;
    transition: color .2s, border-color .2s; }
  &_service {
    margin-right: -1px;
    flex: 0 0 calc(33.33% + 1px);
    @include m {
      margin: 0 0 -1px; }
    &:last-child {
      flex: 0 0 33.33%; } }
  &_service &__content {
    position: relative;
    padding: 10px 10px 15px;
    border: 1px solid $border;
    @include m {
      padding: 8px 10px 10px; }
    &:before,
    &:after {
      display: none; } }
  &_service &__input:checked + &__content {
    border-color: #ca9ba1;
    background: #FCF0F1;
    box-shadow: 0 -3px 0 0 $red inset;
    z-index: 2; }
  &_service &__input:checked + &__content &__type {
    border-color: transparent;
    font-weight: 700;
    color: black; }
  &_service &__pic {
    display: inline-block;
    margin-right: 4px;
    vertical-align: middle; }
  &_date &__content {
    position: relative;
    padding: 2px 5px 10px 5px;
    z-index: 2;
    &:before {
      display: none; }
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDU0IDUwIj4gIDxkZWZzPiAgICA8c3R5bGU+ICAgICAgLmNscy0xIHsgICAgICAgIGZpbGw6ICNmY2YwZjE7ICAgICAgICBzdHJva2U6ICNjYTliYTE7ICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsgICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik03MDMsNjQ2aDUzdjQybC0yNi42LDdMNzAzLDY4OFY2NDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzAyLjUgLTY0NS41KSIvPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      transform: none;
      opacity: 0;
      transition: opacity .2s;
      z-index: -2; } }
  &_date &__input:checked + &__content {
    &:after {
      opacity: 1; } }
  &_date &__input:checked + &__content &__day {
    color: black; }
  &_date &__input:disabled + &__content &__day,
  &_date &__input:disabled + &__content &__date {
    color: #DBDADA; }
  &_time &__content {
    display: inline-block;
    padding: 2px 5px 1px;
    border: 1px solid transparent;
    &:before,
    &:after {
      display: none; } }
  &_time &__input:checked + &__content {
    background: #fcf0f1;
    border: 1px solid #ca9ba1; }
  &_time &__input:checked + &__content &__time {
    border-color: transparent;
    font-weight: 700;
    color: black; }
  &_time &__input:disabled + &__content &__time {
    border-color: transparent;
    color: #dbdada;
    cursor: not-allowed; }
  &_payment {
    margin: 0 -1px -1px 0;
    flex: 0 0 170px;
    align-self: flex-start;
    @include m {
      margin: 0 5px 10px;
      flex: 0 0 calc(50% - 10px); } }
  &_payment &__input:checked + &__content {
    background: #fcf0f1;
    border: 1px solid #ca9ba1;
    z-index: 2; }
  &_payment &__input:checked + &__content &__type {
    font-weight: 700; }
  &_payment &__content {
    display: flex;
    position: relative;
    height: 65px;
    padding: 10px;
    background: #fdfdfd;
    border: 1px solid #d7d7d7;
    align-items: center;
    &:before,
    &:after {
      display: none; } }
  &_payment &__preview {
    padding-right: 10px;
    flex: 0 0 45px;
    @include m {
      flex: 0 0 35px; } }
  &_payment &__pic {
    display: block;
    max-width: 100%;
    margin: 0 auto; }
  &_payment &__pic + &__pic {
    margin-top: 5px; }
  &_payment &__type {
    border: none;
    font-size: 16px;
    color: black;
    line-height: 1; } }







