﻿.overview {
  @include m {
    padding-bottom: 5px; }
  &__list {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap; }
  &__item {
    width: calc(25% - 10px);
    margin: 0 5px 28px;
    flex: 0 0 calc(25% - 10px);
    color: #000;
    @include m {
      position: relative;
      width: calc(50% - 10px);
      margin: 12px 5px;
      flex: 0 0 calc(50% - 10px);
      &:nth-child(2n-1) {
        &:before {
          content: '';
          position: absolute;
          top: -12px;
          left: -10px;
          width: 100vw;
          height: 2px;
          background: #f2f1f1; } } } }
  &__item:hover &__title {
    text-decoration: none; }
  &__preview {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    border: 1px solid $border;
    @include m {
      margin-bottom: 5px; } }
  &__item:hover &__pic__wrap {
    @include m {
      margin-bottom: 5px; } }
  &__preview:hover &__pic__wrap {
      transform: scale(1.2); }
  &__pic {
    max-height: 214.84px;
    display: block;
    width: 100%;
    &__wrap {
      transition: transform 0.5s; }
    &__wrap:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to top, #202020 0%, transparent 87%, transparent 100%);
      opacity: .6; } }
  &__rate {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px;
    font-size: 0; }
  &__title {
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    @include m {
      font-size: 17px; } }
  &__text {
    font-size: 16px;
    @include m {
      font-size: 14px; } }
  &__foot {
    display: none;
    @include m {
      display: block; } } }
