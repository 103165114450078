.rate {
  display: flex;
  & .icon {
    font-size: 23px;
    fill: rgba($white,.63);
    @include m {
      font-size: 19px; } }
  &__wrap {
    padding-top: 4px;
    font-size: 0; }
  &__stars,
  &__value {
    display: inline-block;
    vertical-align: top; }
  &__catalog {
    flex-direction: column; }
  &__item {
    width: 100%;
    margin-right: 1px;
    &__out {
      position: relative; }
    &__in {
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      & .icon {
        fill: $red; } } } }
