@mixin sprite-retina($iconName) {
	background-image: url(icon-attr($iconName, IMG));
	$width: icon-attr($iconName, W);
	$height: icon-attr($iconName, H);
	width: $width/2;
	height: $height/2;
	$x: icon-attr($iconName, X);
	$y: icon-attr($iconName, Y);
	background-position: $x/2 $y/2;
	$tw: icon-attr($iconName, TW);
	$th: icon-attr($iconName, TH);
	background-size: $tw/2 $th/2; }
@mixin sr($i) {
	@include sprite-retina($i); }
$red: #bd0217;
$blue: #287c9f;
$green: #006f00;
$border: #D2D2D2;
$m-border: #e1e1e1;
$gray: #7c7c7c;
$bg: #f8f9fd;
$cl: #2b2828;

.m-hide {
  @include m {
    display: none; } }

.m-show {
  display: none;
  @include m {
    display: block; }
  &-inline {
    display: none;
    @include m {
      display: inline; } } }

.m-check {
  display: block;
  margin-bottom: 10px;
  &__input {
    display: none; }
  &__input:checked + &__wrap &__tick {
    &:before {
      content: '';
      display: block;
      @include sr(tick-mob); } }
  &__input:checked + &__wrap &__stars {
    opacity: 1; }
  &__wrap {
    display: flex; }
  &__stars {
    float: left;
    margin-right: 10px;
    padding-top: 2px;
    font-size: 0;
    opacity: .5; }
  &__counter {
    margin-left: 3px;
    color: #9E9E9E; }
  &__tick {
    float: left;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    padding: 2px 0 0 1px;
    border: 1px solid #383839;
    border-radius: 2px; } }

.m-details {
  display: none;
  margin: 0 -10px 5px;
  @include clr;
  @include m {
    display: block; }
  &__col {
    float: left;
    width: 50%;
    margin-bottom: 5px;
    &:first-child {
      margin-right: -1px;
      width: calc(50% + 1px); }
    &_lg {
      width: 100%; } } }

.m-select {
  position: relative;
  font-size: 17px;
  text-align: center;
  &.open {
    z-index: 1020; }
  &.open &__drop {
    display: block; }
  &__head {
    height: 40px;
    padding: 0 10px;
    background-color: #f8f9fd;
    border: 1px solid $m-border;
    white-space: nowrap;
    line-height: 38px;
    cursor: pointer;
    overflow: hidden;
    >.ico {
      content: '';
      margin: 0 0 2px 5px;
      vertical-align: middle;
      &-filters-mob-r {
        margin: 0 5px 2px 0; } } }
  &__drop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4; }
  &__option {
    height: 40px;
    margin-bottom: -1px;
    background-color: #f8f9fd;
    border: 1px solid $m-border;
    line-height: 38px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    &.active {
      font-weight: 700;
      &:after {
        content: '';
        display: inline-block;
        margin: 0 0 4px 5px;
        @include sr(tick-mob);
        vertical-align: middle; } } }
  &__rating {
    float: left;
    padding: 0 10px 0 20px;
    font-size: 0;
    text-align: left;
    &-counter,
    .ico {
      vertical-align: middle; }
    &-counter {
      display: inline-block;
      margin-left: 5px;
      font-size: 17px; }
    .ico {
      margin-bottom: 2px; } } }
.m-sort {
  margin: 0 -10px;
  @include clr;
  &__select {
    float: left;
    width: 50%;
    margin-bottom: -1px;
    &:first-child {
      margin-right: -1px;
      width: calc(50% + 1px); }
    &-buy {
      position: relative;
      >.m-select__head .ico {
        position: absolute;
        margin: -1px 0 0 0;
        top: 50%;
        right: 5px;
        z-index: 2; }
      >.m-select__head {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 1px;
          bottom: 0;
          width: 35px;
          background: linear-gradient(to right, rgba(237,239,246,0) 0%,rgba(237,239,246,0.65) 30%,rgba(237,239,246,1) 55%,rgba(237,239,246,1) 100%);
          z-index: 1; } } }
    >div {
      background: #edeff6; } }
  &_first-lg &__select {
    width: 40%;
    &:first-child {
      width: 60%; } } }

.m-header {
  display: none;
  position: relative;
  padding: 12px 0;
  background-image: linear-gradient(to top, #303030 0%, #323234 100%);
  box-shadow: 0 1px 3.9px 0.1px rgba(0, 0, 0, 0.3);
  z-index: 10;
  &.upper {
    z-index: 1020; }
  @include m {
    display: block; }
  &__center {
    @include clr; }
  &__col {
    float: left;
    width: 26%;
    &:nth-child(2) {
      width: 41%; }
    &:last-child {
      width: 33%; } }
  &__logo {
    display: block;
    padding: 1px 0 5px;
    text-align: center; }
  &__title {
    padding: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 18px;
    color: #9b9b9b;
    line-height: 28px; }
  &__search {
    position: relative;
    float: right;
    width: 100%;
    height: 28px; }
  &__input[type="text"] {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 28px;
    background-color: white;
    border-radius: 3px;
    box-shadow: none;
    transition: width .4s, height .4s;
    &:focus {
      width: calc(100vw - 20px);
      height: 32px; } }
  &__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 28px;
    background: none;
    border: none; } }

.m-footer {
  display: none;
  background: #383839;
  @include m {
    display: block; }
  &__top {
    width: 100%;
    height: 40px;
    background-color: #5e5f60;
    border: none;
    font-size: 14px;
    color: #e4e4e4;
    text-transform: uppercase;
    &:after {
      content: '';
      display: inline-block;
      margin: 0 0 4px 6px;
      @include arr(10,6,#e4e4e4,t);
      vertical-align: middle; } }
  &__wrap {
    padding: 20px 0;
    text-align: center; }
  &__nav {
    margin-bottom: 5px;
    padding: 0 20px;
    &-link {
      display: inline-block;
      margin: 0 15px 15px;
      font-size: 17px;
      color: #afafaf; } }
  &__social {
    margin-bottom: 8px;
    padding: 0 50px;
    font-style: 0;
    text-align: center;
    height: 30px;
    a {
      float: none!important; }
    &-link {
      display: inline-block;
      margin: 0 12px 12px;
      vertical-align: top; } }
  &__copyright {
    font-size: 14px;
    color: #626262; } }

.m-buy {
  &__title {
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 700; }
  &__list {
    margin: 0 -10px;
    padding-bottom: 15px;
    background: #f8f9fd; }
  &__item {
    display: flex;
    padding: 20px 10px 13px;
    border: 1px solid $m-border;
    border-width: 1px 0;
    background: #fff;
    &:not(:last-child) {
      margin-bottom: 4px; } }
  &__col {
    &:first-child {
      flex: 1;
      overflow: hidden; }
    &:last-child {
      width: 126px;
      margin-left: 10px; } }
  &__preview {
    height: 32px; }
  &__logo {
    max-width: 100%;
    max-height: 100%; }
  &__name {
    position: relative;
    font-size: 20px;
    color: rgba(#000,.8);
    font-weight: 700;
    white-space: nowrap;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20px;
      background: linear-gradient(to right, rgba(#fff,.0), rgba(#fff,1)); } }
  &__button {
    display: block;
    width: 100%;
    height: 32px;
    padding: 5px;
    background-color: #bd0217;
    border-radius: 2px;
    font-size: 17px;
    color: #fff;
    text-align: center;
    .ico {
      margin: 0 0 5px 5px; } }
  &__delivery {
    position: relative;
    margin-top: 5px;
    padding-left: 32px;
    font-size: 14px;
    color: gray;
    .ico {
      position: absolute;
      top: 4px;
      left: 0; } }
  &__foot {
    margin: -5px -10px 0;
    padding: 0 10px 15px;
    background: $bg; } }

.m-section {
  display: none;
  margin: 0 -10px;
  padding: 15px 10px;
  background: $bg;
  @include m {
    display: block; } }

.m-head {
  display: none;
  margin-bottom: 10px;
  border-bottom: 1px solid $m-border;
  @include m {
    display: block; }
  &__wrap {
    padding-bottom: 8px;
    @include clr; }
  &__title {
    float: left;
    font-weight: 700;
    font-size: 21px; }
  &__rate {
    float: right; }
  &__sort {
    margin: 0 -10px;
    border: 1px solid $m-border;
    background: #edeff6; }
  &__select {
    border: 0;
    background: #edeff6; }
  &_reviews {
    margin: -15px -10px 0;
    padding: 0 10px;
    background: #FFF;
    border: none; } }

.m-expert {
  display: none;
  @include m {
    display: block; }
  &__content {
    margin-bottom: 10px;
    font-size: 17px; } }

.m-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: .4;
  z-index: 1010;
  @include m {
    opacity: .3; }
  &.show {
    @include m {
      display: block; } } }

.m-wrapper {
  @include m {
    overflow: hidden; }
  &.hide {
    @include m {
      display: none; } } }

.m-choice {
  display: none;
  flex: 1;
  font-size: 17px;
  &.show {
    @include m {
      display: block; } }
  &__head {
    @include clr; }
  &__body {
    padding-bottom: 65px; }
  &__back,
  &__close {
    height: 50px;
    padding: 0 10px;
    border: none;
    background: none;
    font-size: 0;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle; } }
  &__back {
    float: left; }
  &__close {
    float: right; }
  &__title {
    height: 50px;
    margin: 0 40px;
    text-align: center;
    font-weight: 700;
    line-height: 50px;
    white-space: nowrap;
    overflow: hidden; }
  &__field {
    overflow: hidden; }
  &__input[type='text'] {
    width: 100%;
    height: 50px;
    border: none;
    box-shadow: none;
    font-size: 17px; }
  &__list {
    border-top: 1px solid $m-border; }
  &__item {
    display: block;
    position: relative;
    padding: 9px 30px 9px 10px;
    border-bottom: 1px solid $m-border;
    color: $cl;
    &.active {
      background: $m-border; }
    &_simple {
      &:after {
        display: none; } }
    &:after {
      content: '';
      position: absolute;
      top: 12px;
      right: 10px; } }
  &__city {
    display: block;
    padding: 9px 30px 9px 10px;
    border-bottom: 1px solid $m-border;
    color: $cl;
    &.active {
      background: $m-border;
      font-weight: 700;
      &:after {
        content: '';
        display: inline-block;
        margin: 0 0 2px 5px;
        @include sr(tick-mob);
        vertical-align: middle; } } }
  &_filters &__body {
    padding-bottom: 20px; } }

.m-filters {
  padding: 0 10px;
  &__item {
    margin-bottom: 25px;
    &_brand {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        height: 50px;
        background: linear-gradient(rgba(#fff,0), rgba(#fff,1));
        pointer-events: none; } }
    &_brand-top {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 68px;
        height: 50px;
        background: linear-gradient(rgba(#fff,1), rgba(#fff,0));
        pointer-events: none;
        z-index: 3; } } }
  &__title {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 700; }
  &__cols {
    columns: 2 10px; }
  &__wrap {
    &_scroll {
      max-height: 255px;
      overflow: hidden;
      &-yes {
        overflow-y: auto; } } }
  &__range {
    @include clr; }
  &__brand {
    width: 100%;
    padding: 0 0 10px; }
  &__field {
    float: left;
    width: 50%;
    max-width: 155px;
    padding-right: 20px; }
  &__input[type='text'] {
    width: 100%;
    height: 32px;
    padding: 0 10px;
    border: 1px solid #383839;
    border-radius: 2px;
    box-shadow: none; }
  &__foot {
    margin: 0 -10px;
    padding: 10px;
    border-top: 1px solid $m-border; }
  &__check {
    display: inline-block;
    width: 100%;
    vertical-align: top; } }

.m-group {
  border: 1px solid #383839;
  border-radius: 2px;
  overflow: hidden;
  @include clr;
  &__item {
    float: left;
    width: 33.33%;
    border-left: 1px solid #383839;
    text-align: center;
    &:first-child {
      width: 33.34%;
      border: none; } }
  &__input {
    display: none; }
  &__input:checked + &__content {
    background: #383839;
    color: #fff; }
  &__content {
    display: block;
    font-size: 14px;
    line-height: 30px; } }

.m-stars-check {
  cursor: pointer; }

.check_active .m-check__stars {
  opacity: 1; }

.m-city {
  display: none;
  margin-bottom: 15px;
  @include m {
    display: block; } }

.js-m-show-city {
  border-radius: 4px; }

.m-rate__value {
  @include m {
    display: inline-block!important;
    margin-top: -2px; } }

.m-prod {
  background: #f8f9fd;
  line-height: 1.3;
  &__list {
    margin: 0 -10px; }
  &__item {
    padding: 15px 0;
    background: #FFF;
    margin-bottom: 4px;
    border: 1px solid #e1e1e1;
    border-width: 1px 0;
    @include clr; }
  &__col {
    position: relative;
    float: left;
    width: 66%;
    min-height: 10px;
    padding-right: 40px;
    &:first-child {
      width: 34%;
      padding: 0 10px; } }
  &__rating {
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    background: $red;
    font-size: 16px;
    color: #FFF;
    text-align: center;
    line-height: 33px;
    margin: -6px 3px; }
  &__pic {
    max-width: 100%;
    max-height: 100%; }
  &__title {
    font-weight: 700;
    border-bottom: 1px solid currentColor;
    color: #000; }
  &__params {
    padding: 5px 0 7px;
    font-size: 0; }
  &__option {
    display: inline-block;
    margin-right: 5px;
    font-size: 14px; }
  &__red {
    color: $red; }
  &__link {
    display: table;
    margin-bottom: 15px;
    font-size: 14px;
    color: $blue;
    text-decoration: underline; }
  &__foot {
    padding: 6px 0 10px; }
  &__preview {
    display: block;
    height: 160px;
    margin-bottom: 17px;
    @include vertical;
    @include m {
      max-height: 160px;
      margin: 0; }
    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: top; } } }

.m-error {
  h1 {
    font-weight: normal; }
  h3 {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 18px!important; }
  ul {
    font-size: 17px;
    line-height: 28px; } }

