.pop {
  &__list {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
    @include m {
      display: block;
      margin: 0 -10px;
      overflow: hidden; } }
  &__rating {
    padding-top: 10px;
    display: flex;
    color: $link;
    & span {
      color: black; } }
  &__item {
    display: flex;
    flex-direction: column;
    width: calc(25% - 10px);
    margin: 0 5px 10px;
    padding: 20px;
    border: 1px solid $border;
    flex: 0 0 calc(25% - 10px);
    box-sizing: border-box;
    color: $black;
    @include m {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 0;
      padding: 20px 10px;
      border: 2px solid #f2f1f1;
      border-width: 2px 0 0; } }
  &__item:hover &__pic {
    transform: scale(1.04); }
  &__wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 2; }
  &__item:hover &__title {
    text-decoration: none; }
  &__preview {
    height: 175px;
    margin-bottom: 15px;
    text-align: center;
    overflow: hidden;
    display: flex;
    @include m {
      height: auto;
      padding: 0 15px 0 0;
      flex: 0 0 115px; } }
  &__pic {
    width: auto;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.5s;
    @include m {
      height: auto;
      max-height: 110px; } }
  &__title {
    color: black;
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    @include m {
      font-size: 17px; } }
  &__text {
    margin-bottom: 10px;
    font-size: 16px;
    flex-grow: 2;
    @include m {
      font-size: 14px; } }
  &__rate {
    display: none;
    margin-bottom: 3px;
    font-size: 14px;
    color: #287c9f;
    @include m {
      display: block; } }
  &__value {
    color: $black; }
  &__btn {
    width: 150px;
    align-self: center;
    @include m {
      width: 163px;
      align-self: flex-start; } }
  &__foot {
    display: none;
    padding-top: 5px;
    @include m {
      display: block; } } }
