.hours {
  max-width: 290px;
  margin-bottom: 10px;
  &__title {
    font-weight: 700; }
  &__row {
    display: flex; }
  &__dots {
    height: 13px;
    margin: 0 3px;
    border-bottom: 1px dotted #d1d1d1;
    flex: 1 0 auto; } }

