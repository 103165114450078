.tabs {
  padding-bottom: 5px;
  margin-bottom: 35px;
  border-bottom: 1px solid $border;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2;
  &__list {
    display: flex;
    flex-wrap: wrap; }
  &__link {
    margin: 0 20px 5px 0;
    &_active {
      color: #7c7c7c;
      cursor: default; } } }
